import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "services/axios"
import { INSIGHTS } from "store/serverAPIRoute"

const _data = [
    {
        mediaOwnerName: "media_owner_1",
        mediaOwnerId: 1,
        childCategoryInfoList: [
            {
                label: "level_1",
                value: {
                    level: "level_1",
                    id: 111
                }
            },
            {
                label: "level_2",
                value: {
                    level: "level_2",
                    id: 112
                }
            },
            {
                label: "level_3",
                value: {
                    level: "level_3",
                    id: 113
                }
            },
            {
                label: "level_4",
                value: {
                    level: "level_4",
                    id: 114
                }
            },
            {
                label: "level_5",
                value: {
                    level: "level_5",
                    id: 115
                }
            }

        ]
    },
    {
        mediaOwnerName: "media_owner_2",
        mediaOwnerId: 2,
        childCategoryInfoList: [
            {
                label: "level_16",
                value: {
                    level: "level_16",
                    id: 116
                }
            },
            {
                label: "level_17",
                value: {
                    level: "level_17",
                    id: 117
                }
            },
            {
                label: "level_18",
                value: {
                    level: "level_18",
                    id: 118
                }
            },
            {
                label: "level_19",
                value: {
                    level: "level_19",
                    id: 119
                }
            },
            {
                label: "level_20",
                value: {
                    level: "level_20",
                    id: 120
                }
            }

        ]
    }
]
export const getInsightMediaLevelData = createAsyncThunk(INSIGHTS.MEDIA_OWNER_LEVEL(),
    async ({ params }, { rejectWithValue }) => {
        try {
            const data = await axiosInstance.get(INSIGHTS.MEDIA_OWNER_LEVEL(params?.planId))
            return data.data
        } catch (e) {
            throw rejectWithValue(e)
        }
    }
)

const img_data = [
    {
        "mediaOwnerId": "3024",
        "mediaOwnerName": "ACT0112",
        "childCategoryName": "Convenience Stores < Retail",
        "childCategoryId": "448",
        "imageGalleryList": [
            {
                imageUrl: "https://billboard-images.lifesight.io/australia/mercato_retail_convenience_newsagent.jpg",
                screenId: "11",
                allAudienceReach: "11",
                targetAudienceReach: "11"
            },
            {
                imageUrl: "https://billboard-images.lifesight.io/australia/mercato_retail_convenience_newsagent.jpg",
                screenId: "22",
                allAudienceReach: "11",
                targetAudienceReach: "44"
            },
        ]
    },
    {
        level: 'level_2',
        id: 112,
        media_owner_id: 1,
        imageGalleryList: [
            {
                imageUrl: "https://billboard-images.lifesight.io/australia/mercato_retail_convenience_newsagent.jpg",
                screenId: "11",
                allAudienceReach: "11",
                targetAudienceReach: "11"
            },
            {
                imageUrl: "https://billboard-images.lifesight.io/australia/mercato_retail_convenience_newsagent.jpg",
                screenId: "22",
                allAudienceReach: "11",
                targetAudienceReach: "44"
            },
        ]
    }
]

export const getInsightgalleryImageData = createAsyncThunk(INSIGHTS.GET_PHOTO_GALLERY(),
    async ({ params }, { rejectWithValue }) => {
        try {
            const data = await axiosInstance.get(INSIGHTS.GET_PHOTO_GALLERY(params.planId,params.mediaOwnerId), { })
            return data.data
        } catch (e) {
            throw rejectWithValue(e)
        }
    }
)


