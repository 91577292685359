import { skeleton } from "commons/constants";

export const initialState = {
    mediaOwnerData:{
        ...skeleton,
        data: undefined,
        mediaOwner:undefined
    },
    insightPhotoGallery: {
        ...skeleton,
        data: undefined,
    }
}