import { createSlice } from "@reduxjs/toolkit";
import { fulfilled, pending, rejected } from "commons/constants";
import { getInsightgalleryImageData, getInsightMediaLevelData } from "./insights.action";
import { initialState } from "./initialState";


const Insights = createSlice({
    name: "INSIGHTS",
    initialState,
    reducers: {
        resetLoader(state, { payload }) {
            state[payload.type].loader = initialState[payload.type].loader
        }
    },
    extraReducers: builder => {
        builder.addCase(getInsightMediaLevelData.pending, (state, action) => {
            state.mediaOwnerData.loader = pending
        })
        builder.addCase(getInsightMediaLevelData.fulfilled, (state, { payload }) => {
            state.mediaOwnerData.loader = fulfilled
            state.mediaOwnerData.data = payload.id.map((item,index)=>({
                label:item.mediaOwnerName,
                value:item,
                index
            }))
            state.mediaOwnerData.mediaOwner = payload.id.reduce((current, next) => ({
                ...current,
                [next.mediaOwnerId]: next.mediaOwnerName
            }), {})

        })
        builder.addCase(getInsightMediaLevelData.rejected, (state, { payload }) => {
            state.mediaOwnerData.loader = rejected
            state.mediaOwnerData.error = payload
        })
        
        // Fetch gallery image of media Owner
        builder.addCase(getInsightgalleryImageData.pending, (state, action) => {
            state.insightPhotoGallery.loader = pending
        })
        builder.addCase(getInsightgalleryImageData.fulfilled, (state, { payload }) => {
            state.insightPhotoGallery.loader = fulfilled
            state.insightPhotoGallery.data = payload.id

        })
        builder.addCase(getInsightgalleryImageData.rejected, (state, { payload }) => {
            state.insightPhotoGallery.loader = rejected
            state.insightPhotoGallery.error = payload
        })
    }
})

export const {
    resetLoader
} = Insights.actions;

export default Insights;


